<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <a target="_blank" class="btn btn-dark-blue" :href="unshippedNotExportedOrders">Export Paid Unshipped Orders that were not previously exported</a><br /><br />
            <a target="_blank" class="btn btn-dark-blue" :href="unshippedOrders">Export Paid Unshipped Orders</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  components:{
  },
  data() {
    return {
      loadingUpdate:false,
      untrackedOrders:[],
      user:{
        settings:{
          misc:{

          }
        }
      }
    }
  },

  computed:{
    unshippedNotExportedOrders(){
      return (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL)+'api/v1/export-tracking?exported=0&ps=ZUw2OXzna';
    },
    unshippedOrders(){
      return (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL)+'api/v1/export-tracking?ps=ZUw2OXzna';
    },
    csvTitle(){
      return 'Un-shipped'
    }
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    // this.getTracking();
  },

  methods: {
    getTracking(){
      this.loadingUpdate = true;
      this.$store
          .dispatch('auth/getUnshippedTrackingNumbers')
          .then((res) => {
            this.loadingUpdate = false;
            this.untrackedOrders = res.data.tracking_numbers;
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.trainingForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    }
  },
}
</script>
